import React, {Component} from "react"
import "../css/content.css"
//首页
import bannerUrl from "../images/home/banner.png"
import safeguardUrl from "../images/home/biaoti1.png"
import bz1Url from "../images/home/bz1.png"
import bz2Url from "../images/home/bz2.png"
import bz3Url from "../images/home/bz3.png"
import bz4Url from "../images/home/bz4.png"
import bz5Url from "../images/home/bz5.png"
import bz6Url from "../images/home/bz6.png"
import bz7Url from "../images/home/bz7.png"
import bz8Url from "../images/home/bz8.png"
import bz9Url from "../images/home/bz9.png"
import bz10Url from "../images/home/bz10.png"
import zxzlUrl from "../images/home/zaixianzulin.png"
import biaoti2Url from "../images/home/biaoti2.png"
import weitu1Url from "../images/home/weitu1_1.png"
import why01Url from "../images/home/01.png"
import why02Url from "../images/home/02.png"
import why03Url from "../images/home/03.png"
import why04Url from "../images/home/04.png"
import why05Url from "../images/home/05.png"
import why06Url from "../images/home/06.png"
import why07Url from "../images/home/07.png"
import why08Url from "../images/home/08.png"
import rkUrl from "../images/home/yonghurenke.png"
import zlUrl from "../images/home/zhanlvehezuo.png"

//产品
import product_01 from "../images/products/01.png"
import product_02 from "../images/products/02.png"
import product_03 from "../images/products/03.png"
import product_04 from "../images/products/04.png"
import product_05 from "../images/products/05.png"
import product_06 from "../images/products/06.png"
import product_07 from "../images/products/07.png"
import product_08 from "../images/products/08.png"
import product_09 from "../images/products/09.png"
import product_10 from "../images/products/10.png"
import product_11 from "../images/products/11.png"
import product_12 from "../images/products/12.png"
import product_13 from "../images/products/13.png"
import product_14 from "../images/products/14.png"
import product_15 from "../images/products/15.png"
import product_16 from "../images/products/16.png"
import product_banner01 from "../images/products/banner1.png"
import product_banner02 from "../images/products/banner2.png"
import product_yuan01 from "../images/products/yuan1.png"
import product_yuan02 from "../images/products/yuan2.png"
import liucheng from "../images/products/liucheng.png"
import phoneUrl from "../images/products/biaoti1.png"
import computerUrl from "../images/products/biaoti2.png"
import photoUrl from "../images/products/biaoti3.png"
import digitalUrl from "../images/products/biaoti4.png"
import orderUrl from "../images/products/saoma.png"

//关于我们
import aboutBannerUrl from "../images/about/banner.png"
import aboutLianxiUrl from "../images/about/lianxi.png"
import aboutJianjieUrl from "../images/about/jianjie.png"

class Content extends Component{

	constructor(props){
		super(props)
		this.state = {
			safe_list: [
				{active_src: bz1Url, in_src: bz6Url, out_src: bz1Url},
				{active_src: bz2Url, in_src: bz7Url, out_src: bz2Url},
				{active_src: bz3Url, in_src: bz8Url, out_src: bz3Url},
				{active_src: bz4Url, in_src: bz9Url, out_src: bz4Url},
				{active_src: bz5Url, in_src: bz10Url, out_src: bz5Url},
			],
			why_list: [
				{active_src: why01Url, in_src: why05Url, out_src: why01Url, className: 'why1'},
				{active_src: why02Url, in_src: why06Url, out_src: why02Url, className: 'why2'},
				{active_src: why03Url, in_src: why07Url, out_src: why03Url, className: 'why3'},
				{active_src: why04Url, in_src: why08Url, out_src: why04Url, className: 'why4'},
			],
			phone_list: [
				{active_src: product_01, in: false},
				{active_src: product_02, in: false},
				{active_src: product_03, in: false},
				{active_src: product_04, in: false},
			],
			computer_list: [
				{active_src: product_05, in: false},
				{active_src: product_06, in: false},
				{active_src: product_07, in: false},
				{active_src: product_08, in: false},
			],
			photo_list: [
				{active_src: product_09, in: false},
				{active_src: product_10, in: false},
				{active_src: product_11, in: false},
				{active_src: product_12, in: false},
			],
			digital_list: [
				{active_src: product_13, in: false},
				{active_src: product_14, in: false},
				{active_src: product_15, in: false},
				{active_src: product_16, in: false},
			],
			banner_list: [
				{src: product_banner01, status: true},
				{src: product_banner02, status: false},
			],
			banner_spot_list: [
				{src: product_yuan01, status: true, common: product_yuan02, active: product_yuan01},
				{src: product_yuan02, status: false, common: product_yuan02, active: product_yuan01},
			],
			banner_active_index: 0,
		}


	}

	imgHover = (e) => {
		const target = e.target.dataset.target
		if(target === "safe"){
			const safe_list = this.state.safe_list;
			for(let i in safe_list){
				if(i === e.target.dataset.index){
					safe_list[i].active_src = safe_list[i].in_src;
				}
			}
			this.setState({
				safe_list: safe_list
			})
		}else if(target === "why"){
			const why_list = this.state.why_list;
			for(let i in why_list){
				if(i === e.target.dataset.index){
					why_list[i].active_src = why_list[i].in_src;
				}
			}
			this.setState({
				why_list: why_list
			})
		}else if(target === "product"){
			const type = e.target.dataset.type;

			const phone_list = this.state.phone_list
			const computer_list = this.state.computer_list
			const digital_list = this.state.digital_list
			const photo_list = this.state.photo_list
			if(type === "phone"){
				for(let i in phone_list){
					if(i === e.target.dataset.index){
						phone_list[i].in = true;
					}
				}
			}else if(type === "computer"){
				for(let i in computer_list){
					if(i === e.target.dataset.index){
						computer_list[i].in = true;
					}
				}
			}else if(type === 'digital'){
				for(let i in digital_list){
					if(i === e.target.dataset.index){
						digital_list[i].in = true;
					}
				}
			}else if(type === 'photo'){
				for(let i in photo_list){
					if(i === e.target.dataset.index){
						photo_list[i].in = true;
					}
				}
			}

			this.setState({
				phone_list: phone_list,
				computer_list: computer_list,
				digital_list: digital_list,
				photo_list: photo_list
			})
		}

	}

	imgLeaHover = (e) => {
		const target = e.target.dataset.target
		if(target === "safe"){
			const safe_list = this.state.safe_list;
			for(let i in safe_list){
				if(i === e.target.dataset.index){
					safe_list[i].active_src = safe_list[i].out_src;
				}
			}
			this.setState({
				safe_list: safe_list
			})
		}else if(target === "why"){
			const why_list = this.state.why_list;
			for(let i in why_list){
				if(i === e.target.dataset.index){
					why_list[i].active_src = why_list[i].out_src;
				}
			}
			this.setState({
				why_list: why_list
			})
		}else if(target === 'product'){

			const type = e.target.dataset.type;

			const phone_list = this.state.phone_list
			const computer_list = this.state.computer_list
			const digital_list = this.state.digital_list
			const photo_list = this.state.photo_list
			if(type === "phone"){
				for(let i in phone_list){
					if(i === e.target.dataset.index){
						phone_list[i].in = false;
					}
				}
			}else if(type === "computer"){
				for(let i in computer_list){
					if(i === e.target.dataset.index){
						computer_list[i].in = false;
					}
				}
			}else if(type === 'digital'){
				for(let i in digital_list){
					if(i === e.target.dataset.index){
						digital_list[i].in = false;
					}
				}
			}else if(type === 'photo'){
				for(let i in photo_list){
					if(i === e.target.dataset.index){
						photo_list[i].in = false;
					}
				}
			}

			this.setState({
				phone_list: phone_list,
				computer_list: computer_list,
				digital_list: digital_list,
				photo_list: photo_list
			})
		}
	}

	changeBanner = (e) => {
		var index = 0
		if(e === undefined){
			index = this.state.banner_active_index + 1
			if(index >= this.state.banner_list.length){
				index = 0;
			}
		}else{
			index = e.target.dataset.index	
		}
		index = parseInt(index)
		const banner_list = this.state.banner_list
		for(let i in banner_list){
			if(i == index){
				banner_list[i].status = true;
			}else{
				banner_list[i].status = false;
			}
		}

		const banner_spot_list = this.state.banner_spot_list
		for(let i in banner_spot_list){
			if(i == index){
				banner_spot_list[i].src = banner_spot_list[i].active;
			}else{
				banner_spot_list[i].src = banner_spot_list[i].common;
			}
		}
		this.setState({
			banner_list: banner_list,
			banner_spot_list: banner_spot_list,
			banner_active_index: index
		})
	}

	componentDidMount(){
		this.backInterval = setInterval(()=>{
			this.changeBanner()
        },10000)
	}

	render(){

		const { menu } = this.props;

		if(menu === "product"){
			const PhoneListItems = this.state.phone_list.map((item, index) => 
				<li key={index}>
					{item.in===true?<img className="mark" alt="" data-index={index} data-type="phone" data-target="product" onMouseOut={this.imgLeaHover} src={ orderUrl }/>:''}
					<img className="product" alt="" data-index={index} data-type="phone" data-target="product" onMouseEnter={this.imgHover} src={ item.active_src }/>
				</li>
			)
			const ComputerListItems = this.state.computer_list.map((item, index) => 
				<li key={index}>
					{item.in===true?<img className="mark" alt="" data-index={index} data-type="computer" data-target="product" onMouseOut={this.imgLeaHover} src={ orderUrl }/>:''}
					<img className="product" alt="" data-index={index} data-type="computer" data-target="product" onMouseEnter={this.imgHover} src={ item.active_src }/>
				</li>
			)
			const PhotoListItems = this.state.photo_list.map((item, index) => 
				<li key={index}>
					{item.in===true?<img className="mark" alt="" data-index={index} data-type="photo" data-target="product" onMouseOut={this.imgLeaHover} src={ orderUrl }/>:''}
					<img className="product" alt="" data-index={index} data-type="photo" data-target="product" onMouseEnter={this.imgHover} src={ item.active_src }/>
				</li>
			)
			const DigitalListItems = this.state.digital_list.map((item, index) => 
				<li key={index}>
					{item.in===true?<img className="mark" alt="" data-index={index} data-type="digital" data-target="product" onMouseOut={this.imgLeaHover} src={ orderUrl }/>:''}
					<img className="product" alt="" data-index={index} data-type="digital" data-target="product" onMouseEnter={this.imgHover} src={ item.active_src }/>
				</li>
			)
			const BannerListItems = this.state.banner_list.map((item, index) => 
				<img data-index={index} key={index} alt="" className={item.status==true?"max_img p_banner":"max_img p_banner hide"} src={ item.src }/>
			)
			const BannerSpotListItems = this.state.banner_spot_list.map((item, index) => 
				<img data-index={index} key={index}  onMouseEnter={this.changeBanner} alt="" src={ item.src }/>
			)
			return(
				<div id="product">
					<div className="content">
						<div className="relative">
							<div className="spot">
								{ BannerSpotListItems }
							</div>
							<div>
								{ BannerListItems }
							</div>
						</div>
					</div>
					<div className="content">
						<img alt="" class="max_img" src={ liucheng }/>
					</div>
					<div className="content">
						<div>
							<img alt="" className="title" src={ phoneUrl }/>
						</div>
						<div className="safeguardContent">
							<ul>
								{ PhoneListItems }	
							</ul>
						</div>
					</div>
					<div className="content">
						<div>
							<img alt="" className="title" src={ computerUrl }/>
						</div>
						<div className="safeguardContent">
							<ul>
								{ ComputerListItems }	
							</ul>
						</div>
					</div>
					<div className="content">
						<div>
							<img alt="" className="title" src={ photoUrl }/>
						</div>
						<div className="safeguardContent">
							<ul>
								{ PhotoListItems }	
							</ul>
						</div>
					</div>
					<div className="content">
						<div>
							<img alt="" className="title" src={ digitalUrl }/>
						</div>
						<div className="safeguardContent">
							<ul>
								{ DigitalListItems }	
							</ul>
						</div>
					</div>
				</div>
				);
		}else if(menu === "about"){
			return(
				<div id="about">
					<div className="content">
						<img alt="" className="max_img" src={ aboutBannerUrl }/>
					</div>
					<div className="content">
						<img alt="" className="aut_img" src={ aboutJianjieUrl }/>
					</div>
					<div className="content">
						<img alt="" className="aut_img" src={ aboutLianxiUrl }/>
					</div>
				</div>
				);
		}else{
			const SafeListItems = this.state.safe_list.map((item, index) => 
				<li key={index}><img className="safe" alt="" data-target="safe" data-index={index} onMouseEnter={this.imgHover} onMouseLeave={this.imgLeaHover} src={ item.active_src }/></li>
			)

			const WhyListItems = this.state.why_list.map((item, index) => 
				<div key={index}><img alt="" data-target="why" data-index={index} onMouseEnter={this.imgHover} onMouseLeave={this.imgLeaHover} className={item.className} src={ item.active_src } /></div>
			)

			return(
				<div id="home">
					<div className="content">
						<img alt="" className="max_img" src={ bannerUrl } />
					</div>
					<div className="content">
						<div>
							<img alt="" className="title" src={ safeguardUrl } />
						</div>
						<div className="safeguardContent">
							<ul>
								{ SafeListItems }
							</ul>
						</div>
					</div>
					<div className="content">
						<img alt="" className="max_img" src={ zxzlUrl } />
					</div>
					<div className="content">
						<div>
							<img alt="" className="title" src={ biaoti2Url } />
						</div>
						<div id="why_content">
							{ WhyListItems }
						</div>
						<div>
							<img alt="" className="max_img" src={ weitu1Url } />
						</div>
					</div>
					<div className="content">
						<img alt="" className="max_img" src={ rkUrl } />
					</div>
					<div className="content">
						<img alt="" className="max_img" src={ zlUrl } />
					</div>
				</div>
				);
		}
	}
}

export default Content;
