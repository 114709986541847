import React, {Component} from "react"
import HeaderContent from './Header';
import FooterContent from './Footer';
import Content from './Content';

class CommonContent extends Component{

	constructor() {
		super();
		this.state = {
			active_menu: 0,
			menu_list: ['首页', '产品中心', '关于我们'],
			index_list: ['home', 'product', 'about'],
		};
	}

	changeMenu = (active_index) => {
		this.setState({
			active_menu: active_index,
		})
	}



	render(){
		return(
			<div>
				<HeaderContent active_menu={this.state.active_menu} menu_list={this.state.menu_list} changeMenu={this.changeMenu}/>
				<Content menu={this.state.index_list[this.state.active_menu]}/>
				<FooterContent menu_list={this.state.menu_list} changeMenu={this.changeMenu}/>
			</div>
		);
	}
}

export default CommonContent;

